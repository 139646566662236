import React from 'react';
import './ThermalStore.css'; // Import the stylesheet
import thermalStorePic from './assets/thermalstore.png';
import thermalStoreExplainPic from './assets/ThermalExplain.png'
import { useNavigate } from 'react-router-dom';

const ThermalStore = () => {

const navigate = useNavigate();

const handleClick = () => {
  navigate('/ThermalQuestion');
};

  return (
    <div className="container">
      <h1>Understanding Thermal Store Hot Water Cylinders</h1>

      <p>
        A Thermal Store is essentially a heat battery for hot water and heating and works in reverse to a
        typical unvented or vented cylinder. Rather than relying on instantaneous hot water. A thermal store
        keeps a large volume of water at a high temperature, ready to be used for both hot water and heating.
      </p>

      <figure>
            <img src={thermalStorePic} alt="Thermal Store Diagram" />
            <figcaption>AI generated image of a Thermal Store</figcaption>
      </figure>


        <h2>What is a Thermal Store?</h2>
      <p>
        Unlike a standard hot water cylinder that simply stores domestic hot water (DHW) until it's 
        needed, a thermal store is designed to store energy. This energy can come from a variety of 
        sources—gas or oil boilers, solid-fuel stoves, solar thermal panels, or even heat pumps. 
        Thermal stores tend to be open-vented meaning they operate at atmospheric pressure, unlike
        our Unvented range which operates under pressure.
      </p>
      <p>
        When you turn on a tap, the hot water doesn’t come directly from the stored body of water. 
        Instead, the thermal store passes mains cold water through a coil located at the top of
        the cylinder this instantly heats the water. As a result, you get a steady supply 
        of hot water at mains pressure, our thermal stores come factory fitted with a blending valve,
        to ensure the temperature of the water is 47DegC. A shock arrestor (1L Expansion Vessel) is also
        included to stop water hammer.
      </p>



      <h2>How Does a Thermal Store Work?</h2>
      <p>
        The process can be broken down into a few key steps:
      </p>
      <ol>
        <li>
          <strong>Heat Input:</strong> The thermal store is connected to one or more heat 
          sources—this could be a boiler, a stove with a back boiler, solar panels, or immersion heater.
          These sources heat the water stored in the thermal store.
        </li>
        <li>
          <strong>Heat Storage:</strong> Once heated, the large volume of water in the store acts as 
          a thermal reservoir. This means the heat is “banked” so you don’t have to run your boiler 
          or other heat source continuously.
        </li>
        <li>
          <strong>On-Demand Hot Water:</strong> When you open a hot tap, cold mains water passes 
          through the coil that’s submerged inside the cylinder. The stored hot water 
          transfers its heat to the incoming mains water, providing you with instant hot water 
          at mains pressure.
        </li>
        <li>
          <strong>Space Heating Support:</strong> Many thermal store systems can also be linked to 
          central heating. The store can provide a buffer of hot water to feed radiators or 
          underfloor heating, reducing boiler cycling and improving efficiency.
        </li>
      </ol>

      <figure>
            <img src={thermalStoreExplainPic} alt="Thermal Store Diagram" />
            <figcaption>Basic explaination of a thermal store</figcaption>
      </figure>

      <h2>Key Benefits</h2>
      <ul>
        <li><strong>Versatility:</strong> A thermal store can be integrated with multiple heat sources, including renewables.</li>
        <li><strong>High Pressure at the Tap:</strong> Since the hot water is heated via a mains-fed coil, you get hot water at mains pressure, offering strong shower performance without a pump.</li>
        <li><strong>Improved Efficiency:</strong> By storing heat, you can run your boiler or stove at optimal times, taking advantage of off-peak tariffs or maximising renewable input.</li>
        <li><strong>Reduced Complexity:</strong> Unlike unvented systems, there’s no need for pressure vessels, expansion vessels, or complex safety devices, potentially simplifying installation.</li>
      </ul>

      <h2>Typical Applications and Limitations</h2>
      <p>
        Thermal store cylinders are ideal for homes that have multiple heat sources or those that 
        want to incorporate renewable technologies. They’re often used in off-grid homes, 
        properties with wood-burning stoves and solar panels, or in places where a traditional 
        unvented cylinder installation might be complicated or costly. One downfall of thermal stores
        are the need for higher temperatures, the cylinders operate at 70DegC internally to keep 
        up with heat transfer demand. Heat Pump units do not reach these temperatures therefore
        are not compatible, you need to supplement the cylinder with further heat from another source.
      </p>

      <h2>Maintenance and Considerations</h2>
      <p>
        While thermal stores are robust and reliable, they do require proper installation and 
        occasionally a check-up. It’s essential to ensure that the system’s controls and 
        thermostats are functioning correctly, and that any integrated renewable systems are 
        properly maintained. Always consult with a qualified heating engineer or plumber for 
        advice on sizing, specification, and installation.
      </p>

      <h2>Tristar vs Tristor</h2>
      <p>
        In the Telford range we offer 2 types of thermal store, the Tristar which is the versatile
        store mentioned above and the Tristor Manual Fill, a product specifically designed for solving
        the overflow issue. The Manual Fill does not require the need for an overflow, instead it’s 
        designed with self-containment in mind. Provided with a 2 Port Valve and a Float Switch,
        if the water level inside the cylinder rises too high the cylinder cuts it’s own supply.
      </p>

      <h2>How do you specify a thermal store?</h2>
      <button onClick={handleClick}>Find out now</button>
    </div>
  );
};

export default ThermalStore;
