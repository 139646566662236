export const thermalQuizData = [
    {
      id: 1,
      question: "Do you have a header tank or plan to fit one?",
      type: "single-choice",
      answers: [
        { label: "Yes", value: "header_yes", next: 3 },
        { label: "No", value: "header_no", next: 2 }
      ]
    },
    {
      id: 2,
      question: "Can an Overflow pipe be fitted within the property?",
      type: "single-choice",
      answers: [
        { label: "Yes", value: "overflow_yes", next: 3 },
        { label: "No", value: "overflow_no", next: null }
        // 5 could be a special product route (result) or another question
      ]
    },
    {
      id: 3,
      question: "Which heat sources do you plan to integrate? (Select all that apply)",
      type: "multiple-choice",
      answers: [
        { label: "Electric Only/PV", value: "electric" },
        { label: "Solar Panels", value: "solar" },
        { label: "Stove", value: "stove" },
        { label: "Boiler", value: "boiler" }
      ],
      next: 4
    },
    {
      id: 4,
      question: "Select the cylinder size (litres) that suits your needs",
      type: "single-choice",
      answers: [], // Will be dynamically populated based on Q3 answers.
      next: 6
    },
    {
      id: 6,
      question: "Final Result",
      type: "result"
    }
  ];
  