import React, { useState, useEffect } from 'react';
import { thermalQuizData } from './thermalQuizData';
import './ThermalStore.css';
import TSV1 from './assets/TSV1.png';
import TSV2 from './assets/TSV2.png';
import TSV3 from './assets/TSV3.png';
import TSV4 from './assets/TSV4.png';
import TSV5 from './assets/TSV5.png';
import TSC1 from './assets/TSC1.png';
import TSC2 from './assets/TSC2.png';
import TSC3 from './assets/TSC3.png';
import TSC4 from './assets/TSC4.png';
import TSC5 from './assets/TSC5.png';
import TristorPDF from './assets/Tristor.pdf';

function getSizeOptionsFromSources(sources) {
  const hasBoiler = sources.includes("boiler");
  const hasSolar = sources.includes("solar");
  const hasStove = sources.includes("stove");
  const hasElectric = sources.includes("electric");

  if (hasBoiler && hasSolar) {
    return [200, 250, 300];
  }
  if (hasSolar && !hasBoiler) {
    return [144, 160, 200, 250, 300];
  }
  if (hasBoiler && !hasSolar) {
    return [144, 160, 200, 250, 300];
  }
  if (hasStove && !hasBoiler && !hasSolar) {
    return [115, 135, 144, 160, 200, 250, 300];
  }
  if (hasElectric && !hasStove && !hasBoiler && !hasSolar) {
    return [115, 135, 144, 160, 200, 250, 300];
  }
  return [115, 135, 144, 160, 200, 250, 300];
}

function determineVariant(sources) {
  const hasBoiler = sources.includes("boiler");
  const hasSolar = sources.includes("solar");
  const hasStove = sources.includes("stove");
  const hasElectric = sources.includes("electric");

  if (hasSolar && hasBoiler) return "3XXX";
  if (hasSolar && !hasBoiler) return "2XXX";
  if (hasBoiler && !hasSolar) return "4XXX";
  if (hasStove && !hasSolar && !hasBoiler) return "1XXX";
  if (hasElectric && !hasStove && !hasSolar && !hasBoiler) return "5XXX";

  return "5XXX"; 
}

function generateSizeAnswers(sizeArray) {
  return sizeArray.map(size => ({
    label: `${size}L`,
    value: `${size}`,
    next: 6
  }));
}

const ThermalQuestion = () => {
  const [currentQuestionId, setCurrentQuestionId] = useState(1);
  const [userAnswers, setUserAnswers] = useState({});
  const [tempSelections, setTempSelections] = useState([]);
  const [quizState, setQuizState] = useState([...thermalQuizData]);

  const currentQuestion = quizState.find(q => q.id === currentQuestionId);

  useEffect(() => {
    if (currentQuestionId === 4) {
      const sources = userAnswers[3] || [];
      const sizes = getSizeOptionsFromSources(sources);
      setQuizState(prevState => prevState.map(q => {
        if (q.id === 4) {
          return { ...q, answers: generateSizeAnswers(sizes) };
        }
        return q;
      }));
    }
  }, [currentQuestionId, userAnswers]);

  const handleMultipleChoiceToggle = (value) => {
    setTempSelections(prev => {
      if (prev.includes(value)) {
        return prev.filter(v => v !== value);
      } else {
        return [...prev, value];
      }
    });
  };

  const handleMultipleChoiceNext = () => {
    setUserAnswers(prev => ({ ...prev, [currentQuestionId]: tempSelections }));
    const nextId = currentQuestion.next;
    setCurrentQuestionId(nextId);
    setTempSelections([]);
  };

  const handleSingleChoiceSelect = (answerValue, nextId) => {
    setUserAnswers(prev => ({ ...prev, [currentQuestionId]: [answerValue] }));
    setCurrentQuestionId(nextId);
  };

  const datasheetMapping = {
    'TSV/1XXX': TSV1,
    'TSV/2XXX': TSV2,
    'TSV/3XXX': TSV3,
    'TSV/4XXX': TSV4,
    'TSV/5XXX': TSV5,
    'TSC/1XXX': TSC1,
    'TSC/2XXX': TSC2,
    'TSC/3XXX': TSC3,
    'TSC/4XXX': TSC4,
    'TSC/5XXX': TSC5,
  };

  const getProductType = () => {
    const q1Answer = userAnswers[1] ? userAnswers[1][0] : null; 
    if (q1Answer === "header_yes") {
      return "TSV"; 
    } else if (q1Answer === "header_no") {
      const q2Answer = userAnswers[2] ? userAnswers[2][0] : null;
      if (q2Answer === "overflow_yes") {
        return "TSC";
      } else {
        return "Special"; 
      }
    }
    return "Unknown"; 
  };

  const getRecommendations = () => {
    const productType = getProductType();
    const sources = userAnswers[3] || [];
    const chosenSize = userAnswers[4] ? userAnswers[4][0] : null;
    const variant = determineVariant(sources);
    const productCode = `${productType}${chosenSize}/${variant}`;

    const [codePart, codeVariant] = productCode.split('/');
    const baseType = codePart.substring(0,3);
    const lookupKey = `${baseType}/${codeVariant}`;
    const datasheetImage = datasheetMapping[lookupKey] || null;

    return { productCode, datasheetImage };
  };

  const restartQuiz = () => {
    setCurrentQuestionId(1);
    setUserAnswers({});
    setTempSelections([]);
    setQuizState([...thermalQuizData]);
  };

  if (!currentQuestion) {
    const q1Answer = userAnswers[1] ? userAnswers[1][0] : null;
    const q2Answer = userAnswers[2] ? userAnswers[2][0] : null;
  
    // Check if conditions are met for Special product
    if (q1Answer === "header_no" && q2Answer === "overflow_no") {
      // Immediately return the special product page
      return (
        <div className="container">
          <h1>Special Product: Tristor Manual Fill</h1>
          <p>Download the Tristor Manual Fill Datasheet:</p>
          <a href={TristorPDF} target="_blank" rel="noopener noreferrer">
            View Tristor Manual Fill Datasheet
          </a>
          <br /> <br />
          <button className="btn" onClick={restartQuiz}>Start Over</button>
        </div>
      );
    }
  
    // If not special, proceed as normal with recommendations
    const { productCode } = getRecommendations();
    return (
      <div className="container">
        <h1>Your Recommended Product</h1>
        <p>Product Code: {productCode}</p>
        <button className="btn" onClick={restartQuiz}>Start Over</button>
      </div>
    );
  }
  
  
  

  if (currentQuestion.type === 'result') {
    const { productCode, datasheetImage } = getRecommendations();
    return (
      <div className="container">
        <h1>Your Recommended Product</h1>
        <button className="btn" onClick={restartQuiz}>Start Over</button>
        <p>Product Code: {productCode}</p>
        {datasheetImage ? (
          <div>
            <h2>Datasheet:</h2>
            <img src={datasheetImage} alt="Datasheet" style={{ width: '100%', maxWidth: '600px' }} />
          </div>
        ) : (
          <p>No datasheet available.</p>
        )}
      </div>
    );
  }

  return (
    <div className="container">
      <h1>Thermal Store Selector</h1>
      <p>{currentQuestion.question}</p>

      {currentQuestion.type === "multiple-choice" && (
        <div>
          {currentQuestion.answers.map((answer, idx) => (
            <label key={idx} style={{ display: 'block', marginBottom: '10px' }}>
              <input 
                type="checkbox"
                checked={tempSelections.includes(answer.value)}
                onChange={() => handleMultipleChoiceToggle(answer.value)}
              />
              {answer.label}
            </label>
          ))}
          <button className="btn" onClick={handleMultipleChoiceNext}>Next</button>
        </div>
      )}

      {currentQuestion.type === "single-choice" && (
        <div>
          {currentQuestion.answers.map((answer, idx) => (
            <button 
              className="btn" 
              key={idx} 
              onClick={() => handleSingleChoiceSelect(answer.value, answer.next)}
            >
              {answer.label}
            </button>
          ))}
        </div>
      )}
      <button className="btn" onClick={restartQuiz}>Start Over</button>
    </div>
  );
};

export default ThermalQuestion;
