import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import thermalIcon from './assets/thermal.png'; // Assuming searchIcon was referenced but not imported

import './App.css';

const ProductInfoDashboard = () => {
    const auth = getAuth();
    const navigate = useNavigate();

    const handleThermalStoreClick = () => {
        navigate('/ThermalStore');
    };


    return (
        <div className="dashboard-container">
            <div className="dashboard-header">
                <div className="header-title">
                    {/* Always show 'Dashboard' now */}
                    <h1>Product Information</h1>
                </div>
            </div>

            <div className="widget-container">
                <div className="widget" onClick={handleThermalStoreClick}>
                    <img src={thermalIcon} alt="Thermal Store" />
                    <p>Thermal Stores</p>
                </div>
                {/* Add more widgets here as needed */}
            </div>
        </div>
    );
};

export default ProductInfoDashboard;
